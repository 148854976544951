/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IUploadFilePayload,
  IUploadFileResponse,
  IUploadFilesPayload,
} from '@/interfaces/api/files';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import { apiClient } from '.';

const API_PATH = '/upload';

export const uploadFile = async (
  payload: IUploadFilePayload,
): Promise<IUploadFileResponse> => {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    const res = await apiClient.post(`${API_PATH}/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    throw Error('Failed to upload file');
  }
};

export const uploadFiles = async (payload: IUploadFilesPayload): Promise<any> => {
  try {
    const formData = new FormData();
    payload.files.forEach((file) => {
      formData.append('files', file);
    });
    const res = await apiClient.post(
      `https://dapp.trustless.computer/dapp/api/tools/compile-contract`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return Object(camelCaseKeys(res));
  } catch (err: unknown) {
    console.log('ERROR: ', err);
    throw Error('Failed to upload file');
  }
};
