/* eslint-disable @typescript-eslint/no-explicit-any */
// import SolFileIcon from '@/components/IconSVG/SolFileIcon';
// import SolFileSelectedIcon from '@/components/IconSVG/SolFileSelectedIcon';
import SolFileNewIcon from '@/components/IconSVG/SolFileNewIcon';
import RemoveIcon from '@/components/IconSVG/RemoveIcon';

import Text from '@/components/Text';
import { uploadFiles } from '@/services/file';
import { useMemo, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Loading from './components/Loading';
import SolFileCompiledItem from './components/SolFileCompiledItem';
import { Container, Row } from './style';
// import { FixedSizeList as List } from 'react-window';
import { TransactionEventType } from '@/enums/transaction';
import {
  IDeployContractParams,
  useDeployContract,
} from '@/hooks/contract-operations/remix';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import { DAppType, DeployContractResponse } from '@/interfaces/contract-operation';
import * as TC_SDK from 'trustless-computer-sdk';
import { humanFileSize, parseArgs } from '../utils';
import { useDropzone } from 'react-dropzone';
import ModalDeployStatus from '../components/ModalUploadStatus';

type DeployStausMap = {
  [key: string]: boolean;
};

function DragDropFile() {
  const [drag, setDrag] = useState(false);
  const [fileAlready, setFileAlready] = useState(false);
  const [compileAlready, setCompileAlredy] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [modalStatusShow, setModalStatusShow] = useState(false);

  const [fileList, setFileList] = useState<any[]>([]);
  const [compileFileList, setCompileFileList] = useState<any[]>([]);
  const [compileFileDeployMap, setCompileFileDeployMap] = useState<DeployStausMap>(
    {},
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/sol': ['.sol'],
      'text/sol': ['.sol'],
    },
    onDrop: (files) => {
      // console.log('Drag Files Done ==> ', files);
      if (files.length < 1) {
        setFileAlready(false);
        setFileList([]);
        toast.error('Solidity files not found. Please try again');
      } else {
        setFileAlready(true);
        setFileList(files);
      }
    },
    onDragEnter: () => setDrag(true),
    onDragLeave: () => setDrag(false),
  });

  const totalFileSize = useMemo(() => {
    let totalSize = 0;
    fileList.map((file) => (totalSize += file.size || 0));
    return totalSize;
  }, [fileList]);

  // eslint-disable-next-line no-undef
  const { run } = useContractOperation<
    IDeployContractParams,
    DeployContractResponse | null
  >({
    operation: useDeployContract,
  });

  const renderFileContent = () => {
    if (!fileAlready) {
      return (
        <Row>
          <Text size="medium" color="white" fontWeight="medium">
            {'Drag and drop Solidity files here, or '}
          </Text>
          <div style={{ width: 10 }} />
          <span id="browseText">Browse</span>
        </Row>
      );
    } else {
      return (
        <Text size="medium" color="white" fontWeight="medium">
          {''}
        </Text>
      );
    }
  };

  const renderFileDescription = () => {
    if (fileAlready) {
      const fileCount = fileList.length;
      const fileString = `${fileCount > 1 ? 'files' : 'file'}`;
      return (
        <>
          <p color="white" className="desc-text">
            {`${fileCount} ${fileString} - ${humanFileSize(totalFileSize)}`}
          </p>
          <div
            className="wrapper-remove-ic"
            onClick={() => {
              clearData();
            }}
          >
            <RemoveIcon />
          </div>
        </>
      );
    } else {
      return <p className="desc-text-2">{'(Only support file .sol)'}</p>;
    }
  };

  const compileFiles = async () => {
    try {
      if (fileList) {
        setShowLoading(true);
        const uploadFileResponse = await uploadFiles({ files: fileList });
        // console.log('uploadFileResponse : ', uploadFileResponse);
        setCompileFileList(uploadFileResponse);
        setCompileAlredy(true);
      }
    } catch (error) {
      console.log('UploadFile ERROR: ', error);
      toast.error((error as Error).message);
      setCompileAlredy(false);
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (fileList && fileList.length > 0) {
      compileFiles();
    }
  }, [fileList]);

  const clearData = () => {
    setFileAlready(false);
    setDrag(false);
    setFileList([]);

    setCompileAlredy(false);
    setCompileFileList([]);

    setCompileFileDeployMap({});
  };

  const renderButtonArea = () => {
    if (compileAlready) {
      return (
        <div className="button-area">
          {/* <CancelButton
            onClick={() => {
              clearData();
            }}
          >
            <p className="button-solid-text">Clear</p>
          </CancelButton>
          <div style={{ width: 30 }} /> */}
          {/* <ConfirmButton
            onClick={() => {
              window.open('https://trustlesswallet.io/?tab=transactions', '_blank');
            }}
          >
            <p className="button-solid-text">View Transactions</p>
          </ConfirmButton> */}
        </div>
      );
    }
    if (fileAlready) {
      return (
        <div className="button-area">
          {/* <CancelButton
            onClick={() => {
              clearData();
            }}
          >
            <p className="button-solid-text">Clear</p>
          </CancelButton> */}
          {/* <div style={{ width: 30 }} /> */}
          {/* <ConfirmButton
            onClick={() => {
              compileFiles();
            }}
          >
            <p className="button-solid-text">Compile</p>
          </ConfirmButton> */}
        </div>
      );
    }
  };

  // const renderItem = (props: any) => {
  //   const { style, index } = props;
  //   if (typeof index !== 'number') return null;
  //   const fileCompiled = compileFileList[index];
  //   const { contractName = '', bytecode = '', abi } = fileCompiled;

  //   return (
  //     <SolFileCompiledItem
  //       key={bytecode}
  //       style={...style}
  //       contractName={contractName}
  //       ABI={JSON.stringify(abi || '', null, '\t')}
  //       bytecode={bytecode}
  //     />
  //   );
  // };

  const deploySolFileCompiledHandler = async (
    contractName: string,
    abi: string,
    bytecode: string,
    args: string,
  ) => {
    setShowLoading(true);

    const payload = {
      abi: abi as never,
      bytecode: bytecode as never,
      args: parseArgs(args),
    };

    // console.log('payload: ', payload);

    try {
      const tx = await run(payload);
      if (tx) {
        setTimeout(() => {
          TC_SDK.signTransaction({
            method: `${TransactionEventType.DEPLOY} ${DAppType.Contract}`,
            hash: tx.hash,
            dappURL: window.location.origin,
            isRedirect: true,
            target: '_blank',
          });
        }, 500);
      }
      const newCompileFileDeployMap = { ...compileFileDeployMap };
      newCompileFileDeployMap[bytecode] = true;

      setCompileFileDeployMap(newCompileFileDeployMap);
      setModalStatusShow(true);
    } catch (error) {
      // console.log('deploySolFileCompiledHandler ERROR: ', { abi, bytecode, error });
      toast.error((error as Error).message);
    } finally {
      setShowLoading(false);
    }
  };

  const renderListSolFilesCompiled = () => {
    if (compileFileList && compileFileList.length > 0) {
      // return (
      //   <List
      //     height={600}
      //     itemCount={compileFileList.length}
      //     itemData={compileFileList}
      //     itemSize={200}
      //     width="100%"
      //     className="flatlist"
      //   >
      //     {renderItem}
      //   </List>
      // );
      return (
        <div className="flatlist">
          {compileFileList.map((item) => {
            const { contractName = '', bytecode = '', abi } = item;
            const deployStatus = compileFileDeployMap[bytecode];
            return (
              <SolFileCompiledItem
                key={bytecode}
                contractName={contractName}
                ABI={JSON.stringify(abi || '', null, '\t')}
                bytecode={bytecode}
                deployStatus={deployStatus}
                deployHandler={(args) =>
                  deploySolFileCompiledHandler(contractName, abi, bytecode, args)
                }
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  const overrideProps = fileAlready ? {} : getRootProps({ className: 'dropzone' });
  return (
    <Container>
      {/* Drag File Area */}
      <div
        {...overrideProps}
        className={`dropFileArea ${drag ? `dragEnter` : `dragLeave`}`}
      >
        <input {...getInputProps()} />
        <div className="container-drop-drag">
          <div className="iconWrapper">
            {fileAlready ? <SolFileNewIcon /> : <SolFileNewIcon />}
          </div>
          {renderFileContent()}
          {renderFileDescription()}
        </div>
      </div>

      {/* Button Area */}
      {renderButtonArea()}

      {/* List sole files have been compiled Area */}
      {renderListSolFilesCompiled()}

      {modalStatusShow && (
        <ModalDeployStatus
          show={modalStatusShow}
          handleClose={() => {
            setModalStatusShow(false);
          }}
        />
      )}

      {/* Loading Component */}
      <Loading isVisible={showLoading} />
    </Container>
  );
}

export default DragDropFile;
