import { NextPage } from 'next';
import Layout from '@/layouts';
// import Names from '@/containers/Names';
import Deploy from '@/containers/Deploy';

const HomePage: NextPage = () => {
  return (
    <Layout>
      <Deploy />
    </Layout>
  );
};

export default HomePage;
