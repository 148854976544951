/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@/components/Button';
import px2rem from '@/utils/px2rem';
import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CancelButton = styled(Button)`
  padding: ${px2rem(12)} ${px2rem(24)};
  gap: ${px2rem(10)};
  border-radius: 8px;
  border: 2px solid #ffc008;
  min-width: ${px2rem(200)};
  text-decoration: none !important;
  width: fit-content;

  .button-solid-text {
    font-weight: 500;
    font-size: ${px2rem(18)};
    line-height: ${px2rem(26)};
    text-align: center;
    letter-spacing: 0.01em;
    color: #f9d03f;
    font-family: 'IBMPlexMono' !important;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const ConfirmButton = styled(Button)`
  padding: ${px2rem(12)} ${px2rem(24)};
  gap: ${px2rem(10)};
  border-radius: 8px;
  border: 2px solid #ffc008;
  min-width: ${px2rem(200)};
  text-decoration: none !important;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  width: fit-content;

  .button-solid-text {
    font-weight: 500;
    font-size: ${px2rem(18)};
    line-height: ${px2rem(26)};
    text-align: center;
    letter-spacing: 0.01em;
    color: black;
    font-family: 'IBMPlexMono' !important;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background: '#17171A' !important;

  .iconWrapper {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #browseText {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    /* background: -webkit-linear-gradient(#ff8008, #ffc837); */
    background: #18cdca;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    :hover {
      cursor: pointer;
    }
  }

  #text-container {
    margin-top: 10px;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border: 1px dashed #898989;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    /* background-color: '#17171A'; */
    background: '#17171A' !important;
    background-color: '#17171A' !important;
    border-radius: 12px;
  }

  #label-file-upload.drag-active {
    opacity: 0.8;
  }

  .browse-text {
    /* background: -webkit-linear-gradient(#ff8008, #ffc837); */
    color: '#18CDCA';
    background: '#18CDCA';
    -webkit-text-fill-color: transparent;

    :hover {
      cursor: pointer;
    }
  }

  .desc-text {
    margin-top: 5px;
    font-family: 'Bandeins Sans';
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .desc-text-2 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #898989;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  .button-area {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .flatlist {
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 80px;
    align-self: center;
    overflow-y: hidden;
    /* overflow: auto !important; */
    /* width: 90%; */
  }

  .dropFileArea {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    /* height: 16rem; */
    height: 290px;
    width: 100%;
    max-width: 100%;

    border-width: 2px;
    border-style: dashed;
    border-radius: 12px;

    border: 2px dashed #898989;
    background: #17171a !important;
    background-color: #17171a !important;

    .dragLeave {
      opacity: 0.1;
      background-color: blue;
    }
  }

  .dragEnter {
    opacity: 0.8;
    box-shadow: 0px 0px 15px #ffffff;
  }

  .dragLeave {
    opacity: 1;
    border: 1px dashed #898989;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }

  .wrapper-remove-ic {
    margin-top: 5px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .container-drop-drag {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
