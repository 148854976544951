/* eslint-disable @typescript-eslint/no-explicit-any */
// import Text from '@/components/Text';
import { BodyContainer, NamesContainer, Container, TabContainer } from './styled';
import FormUpload from './Munual/FormUpload';
import DragDropFile from './DragFiles/DragDropFile';
import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
const TABS = {
  Manual: 'Manual',
  DragFile: 'DragFile',
};

const Deploy = () => {
  const [tab, setTab] = useState<string>(TABS.DragFile);

  const renderTabItem = (text: string) => {
    return <p className="tab-text">{text}</p>;
  };

  return (
    <Container>
      <NamesContainer>
        <div className="upload_left">
          {/* <img src={IcImgName} alt="upload file icon" /> */}
          <div className="upload_content">
            <h3 className="upload_title">Trustless IDE</h3>
            <div className="space" />
            <p className="upload_des">
              A simple web-based IDE that lets you deploy smart contracts on Bitcoin
              via Trustless Protocol.
            </p>
            {/* <Text size="medium" maxWidth="100%">
              Develop, debug, and test your contract on Remix Online IDE{' '}
              <a href={'https://remix.ethereum.org/'} target="_blank">
                (https://remix.ethereum.org/)
              </a>{' '}
              .When finished, copy and paste the ABI, Bytecode, and Arguments into the fields below to deploy the
              contract on Trustless Computer.
            </Text>
            <div className="space"></div>
            <Text size="medium" maxWidth="100%">
              Learn more about Remix here.{' '}
              <a href={'https://explorer.trustless.computer'} target="_blank">
                (https://remix-ide.readthedocs.io/en/latest/)
              </a>
            </Text> */}
          </div>
        </div>
      </NamesContainer>
      <BodyContainer>
        <TabContainer>
          {/* <FormUpload /> */}
          {/* <DragDropFile /> */}
          <Tabs
            mountOnEnter
            defaultActiveKey={tab}
            id="uncontrolled-tab"
            onSelect={(key: any) => setTab(key)}
          >
            <Tab eventKey={TABS.DragFile} title={renderTabItem('Drag Files')}>
              <DragDropFile />
            </Tab>
            <Tab eventKey={TABS.Manual} title={renderTabItem('Manual')}>
              <FormUpload />
            </Tab>
          </Tabs>
        </TabContainer>
      </BodyContainer>
    </Container>
  );
};

export default Deploy;
