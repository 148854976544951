/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { WrapInput } from '@/pages/collection/ModalEdit/ModalMint.styled';
import { TransactionEventType } from '@/enums/transaction';
import {
  IDeployContractParams,
  useDeployContract,
} from '@/hooks/contract-operations/remix';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import { DAppType, DeployContractResponse } from '@/interfaces/contract-operation';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as TC_SDK from 'trustless-computer-sdk';
import ModalDeployStatus from '../components/ModalUploadStatus';
import {
  Container,
  SubmitButton,
  WrapInputContainer,
  WrapperContent,
  Title,
} from './styled';
import Loading from '../components/Loading';
import { parseArgs } from '../utils';

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

type IFormValue = {
  ABI: string;
  bytecode: string;
  args: string;
};

const FormUpload = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const textAreaRef = useRef<any>(undefined);
  const [showLoading, setShowLoading] = useState(false);

  const [modalStatusShow, setModalStatusShow] = useState(false);
  const [data, setData] = useState<IFormValue>({
    ABI: '',
    args: '',
    bytecode: '',
  });

  // eslint-disable-next-line no-undef
  const { run } = useContractOperation<
    IDeployContractParams,
    DeployContractResponse | null
  >({
    operation: useDeployContract,
  });
  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};
    const ABI = values.ABI;
    const bytecode = values.bytecode;
    // let args = values.args ;

    /* ABI */
    if (!ABI) {
      errors.ABI = 'ABI is Required.';
    } else {
      if (!isJsonString(ABI)) {
        errors.ABI = 'ABI  must be a JSON string.';
      } else {
        const parseAPI = JSON.parse(ABI);
        if (parseAPI instanceof Array) {
          // TO DO
        } else {
          errors.ABI = 'ABI must be Array.';
        }
      }
    }

    /* ByteCode */
    if (!bytecode) {
      errors.bytecode = 'Bytecode is Required.';
    } else {
      if (typeof bytecode !== 'string') {
        errors.bytecode = 'Bytecode must be string.';
      } else {
        // TO DO
      }
    }

    /* Args */
    // if (!args) {
    //   errors.args = 'Args is required.';
    //   if (!isJsonString(args)) {
    //     errors.args = 'Args  must be a JSON string.';
    //   } else {
    //     // TO DO
    //   }
    // }

    return errors;
  };

  const clearData = () => {
    setData({
      ABI: '',
      args: '',
      bytecode: '',
    });
  };

  const handleSubmit = async (values: IFormValue): Promise<void> => {
    try {
      setIsProcessing(true);
      setShowLoading(true);
      const ABI = JSON.parse(values.ABI) || [];
      const bytecode = values.bytecode;
      const args: any[] = parseArgs(values.args);

      // console.log('args: ', args);
      // const args = JSON.parse('[' + values.args + ']') || [];
      // const args = [];

      // console.log(' ==== ', {
      //   ABI,
      //   bytecode,
      //   args,
      // });

      const tx = await run({
        abi: ABI as never,
        bytecode: bytecode as never,
        args: args,
      });
      if (tx) {
        setTimeout(() => {
          TC_SDK.signTransaction({
            method: `${TransactionEventType.DEPLOY} ${DAppType.Contract}`,
            hash: tx.hash,
            dappURL: window.location.origin,
            isRedirect: true,
            target: '_blank',
          });
        }, 500);
      }
      // CALL API SUBMIT (TO DO )
      setModalStatusShow(true);
    } catch (err) {
      toast.error((err as Error).message);
      console.log(err);
    } finally {
      setIsProcessing(false);
      setShowLoading(false);
    }
  };

  const resizeTextArea = () => {
    if (textAreaRef) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  };

  return (
    <Container>
      <Formik
        key="RemixForm"
        initialValues={data}
        validate={validateForm}
        onSubmit={handleSubmit}
        onReset={clearData}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <WrapInputContainer>
              {/* ABI Here */}
              <WrapperContent>
                <Title>ABI</Title>
                <textarea
                  ref={textAreaRef}
                  id="ABI"
                  name="ABI"
                  onChange={(e) => {
                    resizeTextArea();
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.ABI}
                  className="textarea"
                  placeholder={`Paste ABI here`}
                />
                {errors.ABI && touched.ABI && <p className="error">{errors.ABI}</p>}
              </WrapperContent>

              <div className="space"></div>

              {/* ByteCode Here */}
              <WrapperContent>
                <Title>ByteCode</Title>
                <input
                  id="bytecode"
                  type="text"
                  name="bytecode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bytecode}
                  className="input"
                  placeholder={`Paste Bytecode here`}
                />
                {errors.bytecode && touched.bytecode && (
                  <p className="error">{errors.bytecode}</p>
                )}
              </WrapperContent>

              <div className="space"></div>

              {/* Args Here */}
              <WrapperContent>
                <Title>Args</Title>
                <input
                  id="args"
                  type="text"
                  name="args"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.args}
                  className="input"
                  placeholder={`Enter arguments separated by commas (optional)`}
                />
                {errors.args && touched.args && (
                  <p className="error">{errors.args}</p>
                )}
              </WrapperContent>
            </WrapInputContainer>

            <div className="wrapper-submit-button">
              <SubmitButton disabled={isProcessing} type="submit">
                {isProcessing ? 'Processing...' : 'Submit'}
              </SubmitButton>
            </div>
          </form>
        )}
      </Formik>
      {
        <ModalDeployStatus
          show={modalStatusShow}
          handleClose={() => {
            clearData();
            setModalStatusShow(false);
          }}
        />
      }
      <Loading isVisible={showLoading} />
    </Container>
  );
};

export default FormUpload;
