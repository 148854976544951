/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { WrapInput } from '@/pages/collection/ModalEdit/ModalMint.styled';
import px2rem from '@/utils/px2rem';
import styled, { DefaultTheme } from 'styled-components';

export const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;

  display: flex;
  align-items: center;

  color: #ffffff;

  margin-bottom: 10px;
`;

export const SubmitButton = styled.button`
  height: 50px;

  min-width: 130px;
  max-height: 60px;

  border-radius: 8px !important;
  padding: ${px2rem(4)} ${px2rem(12)};
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  /* background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%); */
  background: #18cdca;
  align-self: flex-end;

  :hover {
    opacity: 0.8;
  }
`;

export const WrapInputContainer = styled.div`
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${px2rem(16)};

  .title-input {
    font-weight: 500;
    font-size: ${px2rem(12)};
    line-height: ${px2rem(20)};
    text-transform: uppercase;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.primary['5b']};
    margin-bottom: ${px2rem(4)};
  }

  .textarea {
    resize: none;
    min-height: 60px;

    width: 100%;
    min-height: 100px;
    max-height: 400px;

    border-radius: 4px;
    overflow-y: scroll;
    display: flex;
    gap: ${px2rem(16)};
    font-weight: 400;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(26)};
    background-color: transparent;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
    border: none;

    ::placeholder {
      color: #a5a5a5;
    }
    :hover {
      opacity: 0.8;
    }
  }

  .input {
    display: flex;
    width: 100%;
    gap: ${px2rem(16)};
    font-weight: 400;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(26)};
    color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
  }

  .error {
    margin-top: 5px;
    font-weight: 400;
    font-size: ${px2rem(14)};
    line-height: ${px2rem(24)};
    color: ${({ theme }: { theme: DefaultTheme }) => theme.text6};
  }
`;

export const Container = styled.div`
  gap: 40px;

  /* background: #17171a; */
  /* border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px; */

  flex-direction: column;
  display: flex;
  justify-content: center;
  justify-items: center;

  .label {
    font-size: ${px2rem(16)};
    font-weight: 500;
    margin-bottom: ${px2rem(6)};
    color: ${({ theme }) => theme.primary['light']};
  }

  .space {
    height: 40px;
  }

  .wrapper-submit-button {
    display: flex;
    justify-content: flex-end;
  }
`;

export const WrapperContent = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11.5px 16px;

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
`;
