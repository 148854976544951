import Spinner from 'react-bootstrap/Spinner';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Description = styled.p`
  padding: 5px;
  color: #1c1c1c;
`;

type LoadingProp = {
  isVisible: boolean;
  message?: string;
  onHide?: () => void;
};

const Loading = (props: LoadingProp) => {
  const { isVisible = false, message = 'Please wait a moment..', onHide } = props;
  return (
    <Modal
      show={isVisible}
      size="sm"
      centered
      backdrop="static"
      keyboard={false}
      onHide={onHide}
    >
      <Modal.Body>
        <Container>
          <Spinner animation="border" role="status"></Spinner>
          <Description>{message}</Description>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Loading;
