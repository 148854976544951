/* eslint-disable @typescript-eslint/no-explicit-any */
export const parseArgs = (args: string) => {
  let list: any[] = [];
  if (args) {
    const array = args.split(',');
    if (array && array.length) {
      list = [...array].map((value) => {
        const number = Number(value);
        if (isNaN(number)) return value;
        return number;
      });
    }
  }
  return list;
};

export const humanFileSize = (bytes: number, decimals?: number) => {
  if (bytes == 0) return '0 Bytes';
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
