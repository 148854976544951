import Button from '@/components/Button';
import px2rem from '@/utils/px2rem';
import styled, { DefaultTheme } from 'styled-components';
export const TabContainer = styled.div`
  .nav-tabs {
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${px2rem(40)};
    margin-top: ${px2rem(40)};
    border-bottom-style: solid;

    border-bottom-color: rgba(255, 255, 255, 0.2);

    .nav-link {
      opacity: 0.5;
      padding: 0 ${px2rem(24)};
      border: none;
      padding-bottom: 8px;
      transition: 0s ease;
      &:hover {
        opacity: 0.75;
      }
    }

    .nav-link.active {
      padding-bottom: 8px;
      background-color: transparent;
      color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
      opacity: 1;
      border-bottom-style: solid;
      border-bottom-color: white;
    }
  }
`;

export const Container = styled.div`
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NamesContainer = styled.div`
  /* margin-left: 10%;
  margin-right: 10%; */
  border-radius: 8px;
  padding: ${px2rem(24)} ${px2rem(32)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
  margin-top: ${px2rem(30)};

  .space {
    height: 20px;
  }

  .upload_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${px2rem(20)};
  }

  .upload_right {
    position: relative;
    overflow: hidden;
  }

  .upload_title {
    flex: 1;
    width: 100%;
    margin-bottom: ${px2rem(8)};
    align-items: center;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;

    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    color: #ffffff;
  }

  .upload_des {
    flex: 1;
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #ffffff;
  }

  .button-text {
    font-family: 'IBMPlexMono' !important;
  }

  .tab-text {
    margin-bottom: 15px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin-top: ${px2rem(24)};
`;

export const SubmitButton = styled(Button)`
  width: ${px2rem(130)};

  p {
    padding: unset !important;
  }

  :disabled {
    opacity: 0.5;
  }
`;
