import SuccessIcon from '@/components/IconSVG/SuccessIcon';
import { ICollection } from '@/interfaces/api/collection';
import { Modal } from 'react-bootstrap';
import { StyledModal, Title, Description } from './Modal.style';

import styled from 'styled-components';
// import { useNavigate } from 'react-router-dom';
// import { ROUTE_PATH } from '@/constants/route-path';

export const Container = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .space {
    height: 20px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #1c1c1c;
  }

  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #898989;
  }

  .doneBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    gap: 10px;
    border-radius: 2px;
    color: white;

    background: #18cdca;
    border-radius: 8px;
  }

  .viewDetail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1c1c1c;
  }

  .clickHere {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1c1c1c;
    text-decoration: underline;

    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

type Props = {
  collection?: ICollection;
  show?: boolean;
  handleClose?: () => void;
  onUpdateSuccess?: () => void;
  title?: string;
  description?: string;
  confirmBtnTitle?: string;
};

const ModalDeployStatus = (props: Props) => {
  const {
    show = false,
    handleClose,
    title = 'Your smart contract is being deployed',
    description = 'Your contract has been deployed successfully on Trustless Computer.',
    confirmBtnTitle = 'Sure thing',
  } = props;
  // const navigate = useNavigate();

  return (
    <StyledModal show={show} onHide={handleClose} centered>
      {/* <Modal.Header>
        <IconSVG className="cursor-pointer" onClick={handleClose} src={IcCloseModal} maxWidth={'22px'} />
      </Modal.Header> */}
      <Modal.Body>
        <Container>
          <SuccessIcon />
          <div className="space" />
          <Title>{title}</Title>
          <div className="space" />
          <Description>{description}</Description>
          <div className="space" />
          <button className="doneBtn" onClick={handleClose}>
            {confirmBtnTitle}
          </button>
          <div className="space" />
          <p className="viewDetail">
            {'To see the contract creation transaction status.'}
          </p>
          <p
            className="clickHere"
            onClick={() => {
              window.open('https://trustlesswallet.io/?tab=transactions', '_blank');
              // navigate(`${ROUTE_PATH.WALLET}?tab=transactions`);
            }}
          >
            {'Click here'}
          </p>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={handleClose}>Save Changes</Button> */}
      </Modal.Footer>
    </StyledModal>
  );
};

export default ModalDeployStatus;
