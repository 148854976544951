/* eslint-disable @typescript-eslint/no-explicit-any */

// import Button from '@/components/Button';
// import CopyIcon from '@/components/Copy';
import px2rem from '@/utils/px2rem';
import { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;

  display: flex;
  align-items: center;

  color: #ffffff;

  margin-bottom: 10px;
`;

export const WrapperContent = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11.5px 16px;

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmButton = styled.button<{ done: boolean }>`
  padding: 10px;
  margin-top: 25px;
  width: 100px;
  align-self: flex-end;
  border: 2px solid ${({ done }) => (done ? 'transparent' : `ffc008`)};
  border-radius: 4px !important;
  background: ${({ done }) => (done ? 'green' : `#18CDCA`)};

  margin-left: 80px;
  min-width: 147px;

  width: 120px;

  max-height: 60px;

  .button-solid-text {
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: white;
  }
  :hover {
    cursor: ${({ done }) => (done ? 'default' : 'pointer')} !important;
    opacity: ${({ done }) => (done ? 1 : 0.8)} !important;
  }
`;

const Container = styled.div<{ deployDone: boolean }>`
  flex: 1;
  align-self: center;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 3px solid
    ${({ deployDone }) => (deployDone ? 'transparent ' : 'transparent')};
  background-color: 'grey';
  min-height: 60px;
  margin-bottom: 40px;
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.bg2};
  background: #17171a;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .button {
    margin-left: 80px;
    width: 120px;
    max-height: 80px;
  }

  .label {
    font-size: ${px2rem(18)};
    font-weight: 500;
    margin-bottom: ${px2rem(8)};
    color: ${({ theme }) => theme.primary['light']};
  }

  .textarea {
    width: 100%;
    min-height: 100px;
    max-height: 400px;

    margin-top: 5px;
    margin-bottom: 30px;
    padding: ${px2rem(12)};
    gap: ${px2rem(16)};

    /* border-radius: 4px; */
    overflow-y: scroll;
    display: flex;

    font-weight: 400;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(26)};

    background-color: transparent;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
    border: none;
  }

  .input {
    display: flex;
    width: 100%;
    gap: ${px2rem(16)};
    font-weight: 400;
    font-size: ${px2rem(16)};
    line-height: ${px2rem(26)};
    color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
  }

  .space {
    height: 20px;
  }
`;

type Props = {
  contractName?: string;
  ABI?: string;
  bytecode?: string;
  args?: string;
  deployStatus?: boolean;
  deployHandler?: (args: string) => void;
  style?: any;
};

const SolFileCompiledItem = (props: Props) => {
  const {
    contractName = '',
    ABI = '',
    bytecode = '',
    deployStatus = false,
    deployHandler = undefined,
  } = props;

  const [args, setArgs] = useState('');

  const argeOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setArgs(newValue);
  };

  const deployAction = () => {
    if (deployStatus) {
      return;
    } else {
      return deployHandler && deployHandler(args);
    }
  };

  return (
    <Container deployDone={deployStatus}>
      <div className="content">
        {/* Contract Name area" */}
        <h6 className="label">{`Contract Name: ${contractName}`}</h6>
        {/*  */}
        {/* Args Here */}
        <Column>
          <WrapperContent>
            <Title>Arguments</Title>
            <input
              id="args"
              type="text"
              name="args"
              value={args}
              onChange={argeOnChange}
              className="input"
              placeholder={`Enter arguments separated by commas (optional)`}
            />
          </WrapperContent>
        </Column>

        <div className="space"></div>

        {/* ABI area" */}
        <Column>
          <WrapperContent>
            <Title>ABI</Title>
            <textarea id="ABI" name="ABI" value={ABI} className="textarea" />
          </WrapperContent>
        </Column>

        <div className="space"></div>

        {/* ByteCode area" */}
        <Column>
          <WrapperContent>
            <Title>Bytecode</Title>
            <input
              type="text"
              name="bytecode"
              value={bytecode}
              className="input"
              disabled={true}
            />
          </WrapperContent>
        </Column>

        <div className="space"></div>

        <ConfirmButton done={deployStatus} onClick={deployAction}>
          <p className="button-solid-text">{`${
            deployStatus ? 'Deployed' : 'Submit'
          }`}</p>
        </ConfirmButton>
      </div>
    </Container>
  );
};

export default SolFileCompiledItem;
