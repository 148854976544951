import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} y={0.25} fill="#fff" fillOpacity={0.1} rx={16} />
    <path
      stroke="#B6B6B6"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.4 12.15h11.2m-7 7v-4.2m2.8 4.2v-4.2m1.4 7h-5.6a1.4 1.4 0 0 1-1.4-1.4v-7.7a.7.7 0 0 1 .7-.7h7a.7.7 0 0 1 .7.7v7.7a1.4 1.4 0 0 1-1.4 1.4Zm-4.2-9.8h2.8a.7.7 0 0 0 .7-.7v-.7a.7.7 0 0 0-.7-.7h-2.8a.7.7 0 0 0-.7.7v.7a.7.7 0 0 0 .7.7Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
